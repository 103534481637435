import React, { Component } from 'react'
import {
    DeleteOutlined
} from "@ant-design/icons";
import { Empty } from 'antd'

export default class AbstractDatabase extends Component {
    render() {
        return (
            this.props.lectures.length > 0 ?
                <div>
                    <div className='database-items'>
                        {
                            this.props.lectures.map(lecture => {
                                return <div key={lecture.id} className="database-single">

                                    <div className="database-item">
                                        <h3><b>{lecture.title}</b></h3>
                                        <h4><b>{lecture.speaker}</b></h4>
                                        {lecture.abstract ? <p>Abstract: {lecture.abstract}</p> : <div></div>}

                                    </div>
                                    <div className="database-delete pointer">
                                        <DeleteOutlined onClick={(e) => this.props.deleteLecture(e, lecture.id)}/>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div> : <div className="empty-database">
                    <Empty description="There is no lectures in database for this meeting" />
                </div>
        )
    }
}
