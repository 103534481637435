import React, { Component } from "react";
import "./Adminpanel.css";
import { Button } from "antd";
import { Link } from "react-router-dom";
import logo from '../assets/escd_logo.svg'

export default class AdminPanel extends Component {
  render() {
    return (
        <div className="main-content">
          <div className="admin-panel-content">
            <div className="logo">
              <img style={{ width: "200px", marginBottom: "35px" }} alt='logo' src={logo}></img>
            </div>
            <div className="choices">
              <Button size={'large'} style={{ height: '60px', width: '200px', marginRight: '15px' }} type="primary">
                <Link to="/cms/study-clubs">Study Clubs</Link>
              </Button>
              <Button size={'large'} style={{ height: '60px', width: '200px' }} type="primary">
                <Link to="/cms/annual-meetings">Annual Meetings</Link>
              </Button>
            </div>
          </div>
        </div>
    );
  }
}
