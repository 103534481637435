import React, { Component } from "react";
import "../styles/DataEntry.css";
import "../../../main.css";
import { Form, Input, Button } from "antd";
import {
  StepForwardOutlined,
  StepBackwardOutlined,
} from "@ant-design/icons";
import VenuesDatabase from './VenuesDatabase'

export default class Venues extends Component {
  state = {
    venue_name: "",
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  submitVenue = (e) => {
    this.props.submitVenue(e, this.state.venue_name)
    this.setState({venue_name: ""})
  }

  render() {
    const isDisabled = this.state.venue_name === "";

    return (
        <div className="form-data">
          <div className="form-left">
            <div className="form-header">
              <h1>
                <b>{this.props.name}</b>
              </h1>
              <p style={{ fontSize: "18px" }}>Venues</p>
            </div>
            <Form
              wrapperCol={{
                span: 24
              }}
              layout="horizontal"
              initialValues={{
                size: "large"
              }}
              size={"large"}
            >

              <Form.Item>
                <Input
                  placeholder="Venue Name"
                  onChange={this.handleChange("venue_name")}
                  defaultValue={this.state.venue_name}
                  value={this.state.venue_name}
                />
              </Form.Item>
              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" disabled={isDisabled} htmlType="submit" onClick={this.submitVenue}>
                  Submit Data
                </Button>
              </Form.Item>
            </Form>

            <div className="form-buttons">
              <Button
                style={{ marginRight: "5px" }}
                size="large"
                icon={<StepBackwardOutlined />}
                type="primary"
                htmlType="button"
                onClick={this.props.prevStep}
              >
                Go Back
            </Button>

              <Button
                style={{ marginLeft: "5px" }}
                icon={<StepForwardOutlined />}
                size="large"
                type="primary"
                htmlType="button"
                onClick={this.props.nextStep}
              >
                Continue
            </Button>
            </div>
          </div>
          <div className="form-right">
            <div className="database-header">
              <h1><b>Database</b></h1>
              <p style={{ fontSize: '18px' }}>Current venues in database</p>
            </div>
            <VenuesDatabase venues={this.props.venues} deleteVenue={this.props.deleteVenue}/>
          </div>
        </div>
    );
  }
}
