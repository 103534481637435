import app from 'firebase/app'
import 'firebase/auth'
import firebase from 'firebase'
import 'firebase/firebase-firestore'
import moment from 'moment'

// let firebaseConfig = {
//     apiKey: "AIzaSyAEQXCXOtrgUVy_2Mh9qlB28oNmucQIt5w",
//     authDomain: "escdtesting.firebaseapp.com",
//     databaseURL: "https://escdtesting.firebaseio.com",
//     projectId: "escdtesting",
//     storageBucket: "escdtesting.appspot.com",
//     messagingSenderId: "919753751709",
//     appId: "1:919753751709:web:a649a672a510f77deee874",
//     measurementId: "G-DXMJCQNQCB"
// };
//

let firebaseConfigProduction = {
    apiKey: "AIzaSyD2qTHtfcdiYIEbMTC9dpUrargs7w6LYHo",
    authDomain: "escd-6d12f.firebaseapp.com",
    databaseURL: "https://escd-6d12f.firebaseio.com",
    projectId: "escd-6d12f",
    storageBucket: "escd-6d12f.appspot.com",
    messagingSenderId: "771024556483",
    appId: "1:771024556483:web:a43343f71a27c4fd366d76",
    measurementId: "G-6TP7N7R6WJ"
};

// Helper methods for Firebase
class Firebase {
    constructor() {
        app.initializeApp(firebaseConfigProduction);
        this.storage = firebase.storage();
        this.auth = app.auth();
        this.db = app.firestore();

    }

    login(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    logout() {
        return this.auth.signOut();
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }

    async getCurrentMeeting(id, collection_name) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(id).get().then(meeting => {
                    resolve(meeting.data());
                })
            })
        } catch (err) {
            throw err;
        }
    }

    async getMeetings(collection_name) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name)
                    .get()
                    .then(meetings => {
                        resolve(meetings.docs.map(meeting => {
                            const meetingId = meeting.id
                            const meetings = meeting.data()
                            meetings.id = meetingId;
                            return meetings
                        }))
                    })
                    .catch(e => reject(e))
            })
        } catch (err) {
            throw err
        }
    }


    async postAnnualMeetings(meeting_title, start_date, end_date, city, image, registration) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("annual_meetings").add({
                        image: 'meetings/' + image,
                        meeting_title: meeting_title,
                        start_date: new Date(start_date),
                        end_date: new Date(end_date),
                        city: city,
                        registration_url: registration
                    }).then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })

        } catch (err) {
            throw err;
        }
    }

    async postStudyClubMeetings(meeting_title, start_date, end_date, city, image, registration) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("study_club_meetings").add({
                        image: 'meetings/' + image,
                        meeting_title: meeting_title,
                        start_date: new Date(start_date),
                        end_date: new Date(end_date),
                        city: city,
                        registration_url: registration
                    }).then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })

        } catch (err) {
            throw err;
        }
    }

    deleteAnnualMeetings(id, collection_name) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(id).delete()
                    .then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }

    async postSpeaker(name, title, biography, image, id) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("annual_meetings").doc(id).collection("speakers").add({
                    image: 'speakers/' + image,
                    name: name,
                    title: title,
                    biography: biography
                }).then(res => resolve(res)).catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }

    async postStudyClubSpeaker(name, title, id) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("study_club_meetings").doc(id).collection("speakers").add({
                    name: name,
                    title: title,
                }).then(res => resolve(res)).catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }

    async getSpeakers(id, collection_name) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(id).collection("speakers")
                    .get()
                    .then(doc => {
                        resolve(doc.docs.map(speakers => {
                            const speakerId = speakers.id
                            const speaker = speakers.data()
                            speaker.id = speakerId;
                            return speaker
                        }))
                    }).catch(e => reject(e))

            })
        } catch (err) {
            throw err;
        }
    }

    async getVenues(id, collection_name) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(id).collection("venues")
                    .get()
                    .then(doc => resolve(doc.docs.map(venues => {
                        const venueId = venues.id
                        const venue = venues.data()
                        venue.id = venueId
                        return venue
                    }))).catch(e => reject(e))

            })
        } catch (err) {
            throw err;
        }
    }

    async postLectureInfo(speaker, title, abstract, id) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("annual_meetings").doc(id).collection("lecture_info").add({
                    speaker: speaker,
                    title: title,
                    abstract: abstract
                }).then(res => resolve(res)).catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }

    async postStudyClubsLectureInfo(speaker, title, id) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("study_club_meetings").doc(id).collection("lecture_info").add({
                    speaker: speaker,
                    title: title,
                }).then(res => resolve(res)).catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }

    async getLectureInfo(id, collection_name) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(id).collection("lecture_info")
                    .get()
                    .then(doc => resolve(doc.docs.map(lectures => {
                        const lectureId = lectures.id
                        const lecture = lectures.data()
                        lecture.id = lectureId;
                        return lecture
                    }))).catch(e => reject(e))

            })
        } catch (err) {
            throw err;
        }
    }

    async postVenue(venue_name, id) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("annual_meetings").doc(id).collection("venues").add({
                    venue_name: venue_name
                }).then(res => {
                    resolve(res)
                }).catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }

    async postStudyClubVenue(venue_name, id) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("study_club_meetings").doc(id).collection("venues").add({
                    venue_name: venue_name
                }).then(res => {
                    resolve(res)
                }).catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }

    async postProgram(start_time, end_time, lecture_title, venue, date, id, lecture_id) {
        let start_time_fixed = new Date(start_time).setFullYear(2000, 0, 1)
        let end_time_fixed = new Date(end_time).setFullYear(2000, 0, 1)
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("annual_meetings").doc(id).collection("program").add({
                    start_time: new Date(start_time_fixed),
                    end_time: new Date(end_time_fixed),
                    lecture: this.db.collection("annual_meetings").doc(id).collection("lecture_info").doc(lecture_id),
                    venue: venue,
                    date: moment(date).format('DD/MM/YYYY').toString(),
                }).then(res => resolve(res)).catch(err => reject(err))
            })
        } catch (err) {
            throw err;
        }
    }

    async postStudyClubsProgram(lecture_title, venue, date, id, lecture_id) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("study_club_meetings").doc(id).collection("program").add({
                    venue: venue,
                    lecture: this.db.collection("annual_meetings").doc(id).collection("lecture_info").doc(lecture_id),
                    date: moment(date).format('DD/MM/YYYY').toString(),
                }).then(res => resolve(res)).catch(err => reject(err))
            })
        } catch (err) {
            throw err;
        }
    }

    async postProgramBreak(id, start_time, end_time, date, break_description, collection_name) {
        let start_time_fixed = new Date(start_time).setFullYear(2000, 0, 1)
        let end_time_fixed = new Date(end_time).setFullYear(2000, 0, 1)
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(id).collection("program").add({
                    start_time: new Date(start_time_fixed),
                    end_time: new Date(end_time_fixed),
                    date: moment(date).format('DD/MM/YYYY').toString(),
                    break_description: break_description,
                }).then(res => resolve(res)).catch(err => reject(err))
            })
        } catch (err) {
            throw err;
        }
    }

    async getProgram(id, collection_name) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(id).collection("program")
                    .orderBy('date', 'asc')
                    .get()
                    .then(doc => resolve(doc.docs.map(programs => {
                        const programId = programs.id
                        const program = programs.data()
                        program.id = programId;
                        return program
                    }))).catch(e => reject(e))

            })
        } catch (err) {
            throw err;
        }
    }

    // Study Clubs Calls
    getStudyClubsMeetings() {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("study_clubs_meetings")
                    .get()
                    .then(meetings => {
                        resolve(meetings.docs.map(studyClubMeeting => {
                            const studyClubMeetingId = studyClubMeeting.id
                            const meeting = studyClubMeeting.data()
                            meeting.id = studyClubMeetingId;
                            return meeting
                        }))
                    })
                    .catch(e => reject(e))
            })
        } catch (err) {
            throw err
        }
    }

    async postStudyClubsMeeting(meeting_title, start_date, end_date, city, image) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection("study_club_meetings").add({
                        image: "",
                        meeting_title: meeting_title,
                        start_date: new Date(start_date),
                        end_date: new Date(end_date),
                        city: city
                    }).then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })

        } catch (err) {
            throw err;
        }
    }

    deleteProgram(collection_name, id, meetingId) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(meetingId).collection('program').doc(id).delete()
                    .then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }
    deleteSpeaker(collection_name, id, meetingId) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(meetingId).collection('speakers').doc(id).delete()
                    .then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }
    deleteLectureInfo(collection_name, id, meetingId) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(meetingId).collection('lecture_info').doc(id).delete()
                    .then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }
    deleteVenues(collection_name, id, meetingId) {
        try {
            return new Promise((resolve, reject) => {
                this.db.collection(collection_name).doc(meetingId).collection('venues').doc(id).delete()
                    .then(res => {
                        resolve(res)
                    })
                    .catch(e => reject(e))
            })
        } catch (err) {
            throw err;
        }
    }
}

export default new Firebase()