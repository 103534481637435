import React, { Component } from 'react'
import {
    DeleteOutlined
} from "@ant-design/icons";
import { Empty } from 'antd'


export default class AbstractDatabase extends Component {
    render() {

        return (
            this.props.venues.length > 0 ?
            <div>
                <div className='database-items'>
                    {
                        this.props.venues.map(venue => {
                            return <div key={venue.id} className="database-single">
                                <div className="database-item">
                                    <h3><b>Venue: {venue.venue_name}</b></h3>
                                </div>
                                <div className="database-delete pointer">
                                    <DeleteOutlined onClick={(e) => this.props.deleteVenue(e, venue.id)}/>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div> : <div className="empty-database">
                <Empty description="There is no venues in database for this meeting" />
            </div>
        )
    }
}
