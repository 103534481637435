import React, { Component } from "react";
import "../styles/DataEntry.css";
import "../../../main.css";
import { Link } from "react-router-dom";
import { Form, Button, DatePicker, Select, TimePicker, Switch, Input } from "antd";
import {
  StepForwardOutlined,
  StepBackwardOutlined,
} from "@ant-design/icons";
import ProgramDatabase from './ProgramDatabase'
import moment from 'moment'

export default class Program extends Component {
  state = {
    start_time: new Date(),
    end_time: new Date(),
    selectedLecture: {},
    venue: "",
    date: moment(this.props.currentMeeting.start_date.seconds * 1000),
    isBreak: false,
    breakDescription: ""
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSelect = (venue) => {
    this.setState({ venue })
  }

  handleSelectLecture = (title, id) => {
    this.setState({ selectedLecture: id })
  }

  addCoffeBreaks = () => {
    this.setState({ isBreak: !this.state.isBreak })
  }

  disabledDate = (current) => {
    const startDate = moment(this.props.currentMeeting.start_date.seconds * 1000)
    const endDate = moment(this.props.currentMeeting.end_date.seconds * 1000)
    return (current && current < startDate) || (current && current > endDate)
  }

  confirmProgram = (e) => {
    this.props.submitProgram(e, this.state.start_time, this.state.end_time, this.state.selectedLecture, this.state.date, this.state.venue, this.state.selectedLecture.key)
    this.setState({
      start_time: new Date(),
      end_time: new Date(),
      breakDescription: ""
    })
  }

  confirmBreak = (e) => {
    this.props.submitBreak(e, this.state.start_time, this.state.end_time, this.state.date, this.state.breakDescription)
    this.setState({
      breakDescription: "",
    })
  }


  render() {
    console.log(this.state)
    const isDisabled = (this.state.venue === "") || (Object.keys(this.state.selectedLecture).length === 0 && this.state.selectedLecture.constructor === Object)
    const isBreakDisabled = this.state.breakDescription === ""
    const { Option } = Select;
    if (this.props.name === "Annual Meetings") {
      return (
        !this.state.isBreak ?
          <div className="form-data">
            <div className="form-left">
              <div className="form-header">
                <h1>
                  <b>{this.props.name}</b>
                </h1>
                <p style={{ fontSize: "18px" }}>Program</p>
              </div>
              <Form
                wrapperCol={{
                  span: 24
                }}
                layout="horizontal"
                initialValues={{
                  size: "large"
                }}
                size={"large"}
              >
                <div className="break-switch">
                  <p>Add breaks</p>
                  <Switch onChange={this.addCoffeBreaks} />
                </div>

                <Form.Item>
                  <TimePicker format="HH:mm" placeholder="Start Time" onChange={(start_time) => this.setState({ start_time })} />
                </Form.Item>
                <Form.Item>
                  <TimePicker format="HH:mm" placeholder="End Time" onChange={(end_time) => this.setState({ end_time })} />
                </Form.Item>
                <Form.Item>
                  <Select
                    defaultValue="Lecture title"
                    style={{ width: "100%" }}
                    onChange={(title, id) => this.handleSelectLecture(title, id)}
                  >
                    {this.props.lectureInfo.map(lecture => {
                      return <Option key={lecture.id} value={lecture.title}>{lecture.title}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Select
                    defaultValue="Venue"
                    style={{ width: "100%" }}
                    onChange={this.handleSelect}
                  >
                    {this.props.venues.map(venue => {
                      return <Option key={venue.id} value={venue.venue_name}>{venue.venue_name}</Option>;
                    })}
                  </Select>

                </Form.Item>
                <Form.Item>
                  <DatePicker
                    defaultValue={this.state.date}
                    disabledDate={this.disabledDate}
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    showToday={false}
                    onChange={(date) => this.setState({ date })}
                  >
                  </DatePicker>
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={isDisabled}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={this.confirmProgram}
                  >
                    Submit Data
                </Button>
                </Form.Item>
              </Form>

              <div className="form-buttons">
                <Button
                  style={{ marginRight: "5px" }}
                  size="large"
                  icon={<StepBackwardOutlined />}
                  type="primary"
                  htmlType="button"
                  onClick={this.props.prevStep}
                >
                  Go Back
              </Button>
                <Link to="/cms">
                  <Button
                    style={{ marginLeft: "5px" }}
                    icon={<StepForwardOutlined />}
                    size="large"
                    type="primary"
                    htmlType="button"
                  >
                    Finish
                </Button>
                </Link>
              </div>
            </div>
            <div className="form-right">
              <div className="database-header">
                <h1><b>Database</b></h1>
                <p style={{ fontSize: '18px' }}>Current programs in database</p>
              </div>
              <ProgramDatabase programs={this.props.programs} deleteProgram={this.props.deleteProgram}/>
            </div>
          </div>
          :

          <div className="form-data">
            <div className="form-left">
              <div className="form-header">
                <h1>
                  <b>{this.props.name}</b>
                </h1>
                <p style={{ fontSize: "18px" }}>Breaks</p>
              </div>
              <Form
                wrapperCol={{
                  span: 24
                }}
                layout="horizontal"
                initialValues={{
                  size: "large"
                }}
                size={"large"}
              >
                <div className="break-switch">
                  <p>Add breaks</p>
                  <Switch onChange={this.addCoffeBreaks} />
                </div>
                <Form.Item>
                  <TimePicker format="HH:mm" placeholder="Start Time" onChange={(start_time) => this.setState({ start_time })} />
                </Form.Item>
                <Form.Item>
                  <TimePicker format="HH:mm" placeholder="End Time" onChange={(end_time) => this.setState({ end_time })} />
                </Form.Item>
                <Form.Item>
                  <DatePicker
                    defaultValue={this.state.date}
                    disabledDate={this.disabledDate}
                    showToday="false"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    onChange={(date) => this.setState(date)}
                  >
                  </DatePicker>
                </Form.Item>
                <Form.Item>
                  <Input placeholder="Break description (e.g coffee time)" onChange={this.handleChange("breakDescription")} value={this.state.breakDescription}/>
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={isBreakDisabled}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={(e) => this.confirmBreak(e)}
                  >
                    Submit Break
                </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="form-right">
              <div className="database-header">
                <h1><b>Database</b></h1>
                <p style={{ fontSize: '18px' }}>Current programs in database</p>
              </div>
              <ProgramDatabase programs={this.props.programs} deleteProgram={this.props.deleteProgram}/>
            </div>
          </div>
      )

    } else {
      return (
        !this.state.isBreak ?
          <div className="form-data">
            <div className="form-left">
              <div className="form-header">
                <h1>
                  <b>{this.props.name}</b>
                </h1>
                <p style={{ fontSize: "18px" }}>Program</p>
              </div>
              <Form
                wrapperCol={{
                  span: 24
                }}
                layout="horizontal"
                initialValues={{
                  size: "large"
                }}
                size={"large"}
              >
                <div className="break-switch">
                  <p>Add breaks</p>
                  <Switch onChange={this.addCoffeBreaks} />
                </div>
                <Form.Item>
                  <Select
                    defaultValue="Lecture title"
                    style={{ width: "70%" }}
                    onChange={this.handleSelectLecture}
                  >
                    {this.props.lectureInfo.map(lecture => {
                      return <Option key={lecture.id} value={lecture.title}>{lecture.title}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Select
                    defaultValue="Venue"
                    style={{ width: "100%" }}
                    onChange={this.handleSelect}
                  >
                    {this.props.venues.map(venue => {
                      return <Option key={venue.id} value={venue.venue_name}>{venue.venue_name}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <DatePicker
                    defaultValue={this.state.date}
                    disabledDate={this.disabledDate}
                    showToday="false"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    onChange={(date) => this.setState({date})}
                  >
                  </DatePicker>
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={isDisabled}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={(e) => this.props.submitStudyClubProgram(e, this.state.start_time, this.state.end_time, this.state.selectedLecture, this.state.date, this.state.venue, this.state.selectedLecture.key)}
                  >
                    Submit Data
                </Button>
                </Form.Item>
              </Form>

              <div className="form-buttons">
                <Button
                  style={{ marginRight: "5px" }}
                  size="large"
                  icon={<StepBackwardOutlined />}
                  type="primary"
                  htmlType="button"
                  onClick={this.props.prevStep}
                >
                  Go Back
              </Button>
                <Link to="/cms">
                  <Button
                    style={{ marginLeft: "5px" }}
                    icon={<StepForwardOutlined />}
                    size="large"
                    type="primary"
                    htmlType="button"
                  >
                    Finish
                </Button>
                </Link>
              </div>
            </div>
            <div className="form-right">
              <div className="database-header">
                <h1><b>Database</b></h1>
                <p style={{ fontSize: '18px' }}>Current programs in database</p>
              </div>
              <ProgramDatabase programs={this.props.programs} deleteProgram={this.props.deleteProgram}/>
            </div>
          </div> :
          <div className="form-data">
            <div className="form-left">
              <div className="form-header">
                <h1>
                  <b>{this.props.name}</b>
                </h1>
                <p style={{ fontSize: "18px" }}>Breaks</p>
              </div>
              <Form
                wrapperCol={{
                  span: 24
                }}
                layout="horizontal"
                initialValues={{
                  size: "large"
                }}
                size={"large"}
              >
                <div className="break-switch">
                  <p>Add breaks</p>
                  <Switch onChange={this.addCoffeBreaks} />
                </div>
                <Form.Item>
                  <TimePicker format="HH:mm" placeholder="Start Time" onChange={(start_time) => this.setState({ start_time })} />
                </Form.Item>
                <Form.Item>
                  <TimePicker format="HH:mm" placeholder="End Time" onChange={(end_time) => this.setState({ end_time })} />
                </Form.Item>
                <Form.Item>
                  <DatePicker
                    defaultValue={this.state.date}
                    disabledDate={this.disabledDate}
                    showToday="false"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    onChange={(date) => this.setState(date)}
                  >
                  </DatePicker>
                </Form.Item>
                <Form.Item>
                  <Input placeholder="Break description (e.g coffee time)" onChange={this.handleChange("breakDescription")} value={this.state.breakDescription}/>
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={isBreakDisabled}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={(e) => this.confirmBreak(e)}
                  >
                    Submit Break
            </Button>
                </Form.Item>
              </Form>
            </div>
            <div className="form-right">
              <div className="database-header">
                <h1><b>Database</b></h1>
                <p style={{ fontSize: '18px' }}>Current programs in database</p>
              </div>
              <ProgramDatabase programs={this.props.programs} deleteProgram={this.props.deleteProgram} />
            </div>
          </div>
      );
    }
  }
}
