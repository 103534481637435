import React, { Component } from 'react'
import {
    DeleteOutlined
} from "@ant-design/icons";
import { Link } from 'react-router-dom'
import { Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default class MeetingInfoDatabase extends Component {
    render() {
        const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        const meetingUrl = this.props.name.toLowerCase().trim().split(/\s+/).join('-')

        if (this.props.isLoading) {
            return (
                this.props.currentMeetings.length > 0 ?
                    <div>
                        <div className='database-items'>
                            {
                                this.props.currentMeetings.map(meeting => {
                                    return <div key={meeting.id} className="database-single">
                                        <Link to={`/cms/${meetingUrl}/${meeting.id}`}>
                                            <div className="database-item pointer">
                                                <h3><b>{meeting.meeting_title}</b></h3>
                                                <p>{meeting.city}</p>
                                            </div>
                                        </Link>
                                        <div className="database-delete pointer" onClick={() => this.props.deleteMeeting(meeting.id)}>
                                            <DeleteOutlined />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div> : <div>
                        <div className="empty-database">
                            <Empty description="No active meetings in database." />
                        </div>
                    </div>
            )
        } else {
            return <div><Spin size="large" style={spinnerStyle} indicator={loadingIcon} /></div>
        }
    }
}


const spinnerStyle = {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    alignItems: 'center',
    right: '0',
    left: '0',
    bottom: '0',
    top: '0'
}
