import React from 'react';
import 'antd/dist/antd.css';
import { Steps } from 'antd';
import { withRouter } from 'react-router-dom';

const { Step } = Steps;

class Progress extends React.Component {

    toMeeting(e) {
        e.preventDefault();
        this.props.inStudyClub ? this.props.history.push('/cms/annual-meetings') : this.props.history.push('/cms/study-clubs')
    }

    render() {
        return (this.props.current !== 0 ? 
            <div className="current-steps">
                <Steps
                    size="small"
                    type="navigation"
                    current={this.props.current}
                    onChange={this.props.selectStep}
                    className="site-navigation-steps"
                >
                    <Step onClick={(e) => this.toMeeting(e)} status="process" title="Meeting Info" description={"Pick a meeting or create new one"} />
                    <Step disabled={!this.props.isLoading} status="process" title="Speaker" description={"Create new speaker in selected meeting"} />
                    <Step disabled={!this.props.isLoading} status="process" title="Lecture Info" description={"Add lecture info in selected meeting"} />
                    <Step disabled={!this.props.isLoading} status="process" title="Venues" description={"Add new venue in selected meeting"} />
                    <Step disabled={!this.props.isLoading} status="process" title="Program" description={"Create program for selected meeting"} />
                </Steps>
            </div> : <div className="current-steps">
                <Steps
                    size="small"
                    type="navigation"
                    current={this.props.current}
                    onChange={this.props.selectStep}
                    className="site-navigation-steps"
                >
                    <Step onClick={(e) => this.toMeeting(e)} status="process" title="Meeting Info" description={"Pick a meeting or create new one"} />
                    <Step status="wait" title="Speaker" description={"Create new speaker in selected meeting"} />
                    <Step status="wait" title="Lecture Info" description={"Add lecture info in selected meeting"} />
                    <Step status="wait" title="Venues" description={"Add new venue in selected meeting"} />
                    <Step status="wait" title="Program" description={"Create program for selected meeting"} />
                </Steps>
            </div>
        )
    }

}


export default withRouter(Progress);