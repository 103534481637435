import React, { Component } from "react";
import "../styles/DataEntry.css";
import "../../../main.css";
import { Form, Input, Button, Select } from "antd";
import {
  StepForwardOutlined,
  StepBackwardOutlined,
} from "@ant-design/icons";
import AbstractDatabase from './AbstractDatabase'

export default class Abstracts extends Component {
  state = {
    speakers: [],
    lectureTitle: "",
    abstract: "",
    selectedSpeakers: "",
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSelect = (selectedSpeaker) => {
    this.setState({ selectedSpeaker })
  }

  submitLecture = (e) => {
    this.props.submitLectureInfo(e, this.state.selectedSpeaker, this.state.lectureTitle, this.state.abstract)
    this.setState({ lectureTitle: "", abstract: "" })
  }

  render() {
    const { Option } = Select;
    const { TextArea } = Input;
    const isDisabled = this.state.lectureTitle === "";

    if (this.props.name === 'Annual Meetings') {
      return (
        <div className="form-data">
          <div className="form-left">
            <div className="form-header">
              <h1>
                <b>{this.props.name}</b>
              </h1>
              <p style={{ fontSize: "18px" }}>Lecture Info</p>
            </div>
            <Form
              wrapperCol={{
                span: 24
              }}
              layout="horizontal"
              initialValues={{
                size: "large"
              }}
              size={"large"}
            >
              <Form.Item>
                <Select
                  mode="multiple"
                  placeholder="Speakers"
                  style={{ width: "100%" }}
                  onChange={this.handleSelect}

                >
                  {this.props.speakers.map(speaker => {
                    return <Option key={speaker.id} value={speaker.name}>{speaker.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Input
                  placeholder="Lecture Title"
                  value={this.state.lectureTitle}
                  onChange={this.handleChange("lectureTitle")}
                  defaultValue={this.state.lectureTitle}
                />
              </Form.Item>
              <Form.Item>
                <TextArea
                  rows={4}
                  style={{ resize: "none" }}
                  placeholder="Abstract"
                  onChange={this.handleChange("abstract")}
                  value={this.state.abstract}
                  defaultValue={this.state.abstract}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  onClick={this.submitLecture}
                  style={{ width: "100%" }}
                  type="primary"
                  disabled={isDisabled}
                >
                  Submit Data
                </Button>
              </Form.Item>
            </Form>

            <div className="form-buttons">
              <Button
                style={{ marginRight: "5px" }}
                size="large"
                icon={<StepBackwardOutlined />}
                type="primary"
                htmlType="button"
                onClick={this.props.prevStep}
              >
                Go Back
              </Button>

              <Button
                style={{ marginLeft: "5px" }}
                icon={<StepForwardOutlined />}
                size="large"
                type="primary"
                htmlType="button"
                onClick={this.props.nextStep}
              >
                Continue
              </Button>
            </div>
          </div>
          <div className="form-right">
            <div className="database-header">
              <h1><b>Database</b></h1>
              <p style={{ fontSize: '18px' }}>Current lectures in database</p>
            </div>
            <AbstractDatabase lectures={this.props.lectures} deleteLecture={this.props.deleteLecture}/>
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-data">
          <div className="form-left">
            <div className="form-header">
              <h1>
                <b>{this.props.name}</b>
              </h1>
              <p style={{ fontSize: "18px" }}>Lecture Info</p>
            </div>
            <Form
              wrapperCol={{
                span: 24
              }}
              layout="horizontal"
              initialValues={{
                size: "large"
              }}
              size={"large"}
            >
              <Form.Item>
                <Select
                  mode="multiple"
                  placeholder="Speakers"
                  style={{ width: "100%" }}
                  onChange={this.handleSelect}

                >
                  {this.props.speakers.map(speaker => {
                    return <Option key={speaker.id} value={speaker.name}>{speaker.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Input
                  placeholder="Lecture Title"
                  value={this.state.lectureTitle}
                  onChange={this.handleChange("lectureTitle")}
                  defaultValue={this.state.lectureTitle}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={this.submitLecture}
                  style={{ width: "100%" }}
                  type="primary"
                  disabled={isDisabled}
                >
                  Submit Data
              </Button>
              </Form.Item>
            </Form>

            <div className="form-buttons">
              <Button
                style={{ marginRight: "5px" }}
                size="large"
                icon={<StepBackwardOutlined />}
                type="primary"
                htmlType="button"
                onClick={this.props.prevStep}
              >
                Go Back
            </Button>

              <Button
                style={{ marginLeft: "5px" }}
                icon={<StepForwardOutlined />}
                size="large"
                type="primary"
                htmlType="button"
                onClick={this.props.nextStep}
              >
                Continue
            </Button>
            </div>
          </div>
          <div className="form-right">
            <div className="database-header">
              <h1><b>Database</b></h1>
              <p style={{ fontSize: '18px' }}>Current lectures in database</p>
            </div>
            <AbstractDatabase lectures={this.props.lectures} deleteLecture={this.props.deleteLecture}/>
          </div>
        </div>
      );
    }
  }
}
