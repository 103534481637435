import React, { Component } from "react";
import "./header.css";
import { LogoutOutlined, HomeOutlined, ForwardOutlined } from '@ant-design/icons'
import { withRouter, Link } from 'react-router-dom'
import firebase from '../../services/firebase'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  onLogout = async () => {
    try {
      await firebase.logout();
      this.props.history.push("cms/login");
    } catch (error) {
      alert(error.message);
    }
  }

  onCollapse = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    return (
      <div className={this.state.open ? "header-left-open" : "header-left"}>
        <div className="header-top">
          <div className="home header-item">
            <Link to="/cms">
              <HomeOutlined className="header-icon" /><p>Homepage</p>
            </Link>
          </div>
          <div onClick={this.onLogout} className="logout pointer header-item">
            <LogoutOutlined className="header-icon" /><p>
              Logout</p>
          </div>

        </div>
        <ForwardOutlined className="header-icon" onClick={this.onCollapse} />
      </div>
    );
  }
}

export default withRouter(Header);
