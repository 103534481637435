import React, { Component } from 'react'
import firebase from '../../services/firebase'
import Venues from "../DataEntry/Venues/Venues";
import Speakers from "../DataEntry/Speakers/Speakers";
import Abstracts from "../DataEntry/Abstracts/Abstracts";
import Program from "../DataEntry/Program/Program";
import { Redirect, withRouter } from 'react-router-dom';
import { message } from "antd";
import Progress from '../Progress'
import moment from 'moment'

class SingleMeeting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      speakers: [],
      venues: [],
      currentMeeting: [],
      lectureInfo: [],
      programs: [],
      meetingId: this.props.match.params.id,
      image: null,
      url: '',
      uploadFinished: false,
    };

  }


  componentDidMount() {
    this.getSingleMeetingData(this.state.meetingId)
  }

  handleImageChange = image => {
    this.setState({ image: image })
  }

  getSingleMeetingData = async (id) => {
    const speakers = await firebase.getSpeakers(id, "study_club_meetings");
    const venues = await firebase.getVenues(id, "study_club_meetings");
    const lectureInfo = await firebase.getLectureInfo(id, "study_club_meetings");
    const programs = await firebase.getProgram(id, "study_club_meetings")
    const currentMeeting = await firebase.getCurrentMeeting(id, "study_club_meetings")
    this.setState({
      lectureInfo: lectureInfo,
      speakers: speakers,
      venues: venues,
      programs: programs,
      isLoading: true,
      currentMeeting: currentMeeting
    })
  }

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  selectStep = (step) => {
    this.setState({ step: step });
  };

  submitSpeaker = async (e, name, title, biography) => {
    e.preventDefault();
    const createSpeaker = await firebase.postStudyClubSpeaker(name, title, this.state.meetingId)
    const newSpeaker = {
      name: name,
      title: title,
      biography: biography,
      id: createSpeaker.id,
    }
    this.setState({
      speakers: [...this.state.speakers, newSpeaker],
      uploadFinished: false

    });
    message.success("Speaker submitted")
  };

  imageUpload = ({ onSuccess, onError, file, onProgress }) => {
    const uploadTask = firebase.storage.ref(`speakers/${file.name}`).put(file);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        onProgress({ percent: progress })
      },
      (error) => {
        onError(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          onSuccess(downloadURL)
        });
        this.setState({ uploadFinished: true })
      }
    )
  }

  submitLectureInfo = async (e, speaker, title, abstract) => {
    e.preventDefault();
    const lectureInfo = await firebase.postStudyClubsLectureInfo(speaker, title, this.state.meetingId)
    const newLecture = {
      speaker: speaker,
      title: title,
      id: lectureInfo.id
    }
    this.setState({
      lectureInfo: [...this.state.lectureInfo, newLecture]
    })
    message.success("Lecture info submitted")
  }

  submitVenue = async (e, venue_name) => {
    e.preventDefault();
    const createVenue = await firebase.postStudyClubVenue(venue_name, this.state.meetingId);
    const newVenue = {
      venue_name: venue_name,
      id: createVenue.id
    }
    this.setState({
      venues: [...this.state.venues, newVenue]
    })
    message.success("Venue submitted")
  }

  submitStudyClubProgram = async (e, start_time, end_time, lecture_title, date, venue, lecture_id) => {
    e.preventDefault();
    const createProgram = await firebase.postStudyClubsProgram(lecture_title, venue, date, this.state.meetingId, lecture_id)
    const newProgram = {
      date: moment(date).format('DD/MM/YYYY'),
      venue: venue,
      id: createProgram.id
    }
    this.setState({
      programs: [...this.state.programs, newProgram]
    })
    message.success("Program submitted")
  }

  submitBreak = async (e, start_time, end_time, date, breakDescription) => {
    e.preventDefault();
    await firebase.postProgramBreak(this.state.meetingId, start_time, end_time, date, breakDescription, "study_club_meetings")
    message.success("Break submitted")
  }

  deleteProgram = async (e, id) => {
    e.preventDefault();
    let oldPrograms = this.state.programs;
    let newProgramsArray = [];
    newProgramsArray = oldPrograms.filter(program => program.id !== id)
    this.setState({ programs: newProgramsArray })
    await firebase.deleteProgram("study_club_meetings", id, this.state.meetingId);
    message.success("Program successfully deleted")
  }

  deleteSpeaker = async (e, id) => {
    e.preventDefault();
    let oldSpeakers = this.state.speakers;
    let newSpeakersArray = [];
    newSpeakersArray = oldSpeakers.filter(speaker => speaker.id !== id)
    this.setState({ speakers: newSpeakersArray })
    await firebase.deleteSpeaker("study_club_meetings", id, this.state.meetingId);
    message.success("Speaker successfully deleted")
  }

  deleteVenue = async (e, id) => {
    e.preventDefault();
    let oldVenues = this.state.venues;
    let newVenuesArray = [];
    newVenuesArray = oldVenues.filter(venue => venue.id !== id)
    this.setState({ venues: newVenuesArray })
    await firebase.deleteVenues("study_club_meetings", id, this.state.meetingId);
    message.success("Venue successfully deleted")
  }

  deleteLectureInfo = async (e, id) => {
    e.preventDefault();
    let oldLectures = this.state.lectureInfo;
    let newLecturesArray = [];
    newLecturesArray = oldLectures.filter(lecture => lecture.id !== id)
    this.setState({ lectureInfo: newLecturesArray })
    await firebase.deleteLectureInfo("study_club_meetings", id, this.state.meetingId);
    message.success("Lecture successfully deleted")
  }

  render() {
    const step = this.state.step;
    switch (step) {
      case 1:
        return (
          <div className="main">
            <Speakers
              deleteSpeaker={this.deleteSpeaker}
              imageUpload={this.imageUpload}
              isLoading={this.state.isLoading}
              handleImageChange={this.handleImageChange}
              selectStep={this.selectStep}
              name={"Study Clubs"}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              submitSpeaker={this.submitSpeaker}
              speakers={this.state.speakers}
              step={step}
              uploadFinished={true}
            />
            <Progress current={this.state.step} selectStep={this.selectStep} isLoading={this.state.isLoading} />
          </div>
        );
      case 2:
        return (
          <div className="main">
            <Abstracts
              deleteLecture={this.deleteLectureInfo}
              name={"Study Clubs"}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              step={step}
              submitLectureInfo={this.submitLectureInfo}
              lectures={this.state.lectureInfo}
              speakers={this.state.speakers}
            />
            <Progress current={this.state.step} selectStep={this.selectStep} isLoading={this.state.isLoading} />
          </div>
        );
      case 3:
        return (
          <div className="main">
            <Venues
              name={"Study Clubs"}
              deleteVenue={this.deleteVenue}
              venues={this.state.venues}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              step={step}
              submitVenue={this.submitVenue}
            />
            <Progress current={this.state.step} selectStep={this.selectStep} isLoading={this.state.isLoading} />
          </div>
        );
      case 4:
        return (
          <div className="main">
            <Program name={"Study Clubs"}
              submitBreak={this.submitBreak}
              deleteProgram={this.deleteProgram}
              lectureInfo={this.state.lectureInfo}
              venues={this.state.venues}
              nextStep={this.nextStep}
              prevStep={this.prevStep} step={step}
              speakers={this.state.speakers}
              programs={this.state.programs}
              submitStudyClubProgram={this.submitStudyClubProgram}
              currentMeeting={this.state.currentMeeting}
            />
            <Progress current={this.state.step} selectStep={this.selectStep} isLoading={this.state.isLoading} />
          </div>
        )

      default:
        return <Redirect to="/cms"></Redirect>;
    }
  }
}


export default withRouter(SingleMeeting)