import React, { Component } from 'react'
import {
    DeleteOutlined
} from "@ant-design/icons";
import { Empty, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';



export default class SpeakersDatabase extends Component {

    render() {
        const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        if (this.props.isLoading) {
            return (
                this.props.speakers.length > 0 ?
                    <div className='database-items'>
                        {
                            this.props.speakers.map(speaker => {
                                return <div key={speaker.id} className="database-single">
                                    <div className="database-item">
                                        <h3><b>{speaker.name}</b></h3>
                                        <h4><b>{speaker.title}</b></h4>
                                        {speaker.biography ? <p>Biography: {speaker.biography}</p> : <div></div>}
                                    </div>
                                    <div className="database-delete pointer">
                                        <DeleteOutlined onClick={(e) => this.props.deleteSpeaker(e, speaker.id)}/>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    :
                    <div className="empty-database">
                        <Empty description="There is no speakers in database for this meeting" />
                    </div>
            )
        } else {
            return <div><Spin size="large" style={spinnerStyle} indicator={loadingIcon} /></div>
        }
    }
}

const spinnerStyle = {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    alignItems: 'center',
    right: '0',
    left: '0',
    bottom: '0',
    top: '0'
}
