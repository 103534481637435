import React, { Component } from "react";
import MeetingInfo from "../DataEntry/MeetingInfo/MeetingInfo";
import firebase from "../../services/firebase";
import { message } from "antd";
import Progress from '../Progress'
export default class AnnualMeetings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      currentMeetings: [],
      image: null,
      meetingId: "",
      isLoading: false,
      uploadFinished: false,
    };


  }

  componentDidMount() {
    this.getAnnualData();
  };

  getAnnualData = async () => {
    const getMeetings = await firebase.getMeetings("annual_meetings");
    this.setState({
      currentMeetings: getMeetings,
      isLoading: true,
    })
  }

  deleteAnnualMeeting = async (id) => {
    let oldMeetings = this.state.currentMeetings;
    let newMeetingsArray = [];
    newMeetingsArray = oldMeetings.filter(meeting => meeting.id !== id);
    this.setState({ currentMeetings: newMeetingsArray })
    await firebase.deleteAnnualMeetings(id, "annual_meetings");
  }

  submitAnnualMeeting = async (e, meeting_title, start_date, end_date, city, registration) => {
    e.preventDefault();
    const addMeeting = await firebase.postAnnualMeetings(meeting_title, start_date, end_date, city, this.state.image.file.name, registration)
    const newAnnualMeeting = {
      city: city,
      meeting_title: meeting_title,
      start_date: start_date,
      end_date: end_date,
      id: addMeeting.id
    }
    this.setState({
      meetingId: addMeeting.id,
      currentMeetings: [...this.state.currentMeetings, newAnnualMeeting],
      uploadFinished: false,
    })
    message.success(`Meeting submitted.`);
  }


  handleImageChange = image => {
    this.setState({ image: image })
  }

  imageUpload = ({ onSuccess, onError, file, onProgress }) => {
    const uploadTask = firebase.storage.ref(`meetings/${file.name}`).put(file);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        onProgress({ percent: progress })
      },
      (error) => {
        onError(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          onSuccess(downloadURL)
        });
        this.setState({ uploadFinished: true })
      },
    )
  }

  render() {
    return (
      <div className="main">
        <MeetingInfo
          handleImageChange={this.handleImageChange}
          imageUpload={this.imageUpload}
          uploadFinished={this.state.uploadFinished}
          isLoading={this.state.isLoading}
          name={"Annual Meetings"}
          submitMeeting={this.submitAnnualMeeting}
          currentMeetings={this.state.currentMeetings}
          step={this.state.step}
          meetingId={this.state.meetingId}
          deleteMeeting={this.deleteAnnualMeeting}
        />
        <Progress current={this.state.step} selectStep={this.selectStep} />
      </div>
    )
  }
}

