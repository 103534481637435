import React, { PureComponent } from 'react'
import {
    DeleteOutlined
} from "@ant-design/icons";
import { Empty } from 'antd'
import moment from 'moment';

export default class ProgramDatabase extends PureComponent {

    shouldDateBeVisible = (currentProgram) => {
        let currentProgramIndex = this.props.programs.findIndex(program => program.id === currentProgram.id);
        if (currentProgramIndex < 1) return false;
        let currentProgramDate = currentProgram.date;
        let previousProgramDate = this.props.programs[currentProgramIndex - 1].date;

        return currentProgramDate === previousProgramDate;
    }

    render() {
        console.log(this.props)
        return (
            this.props.programs.length > 0 ?
                <div className='database-items'>
                    {
                        this.props.programs.map(program => {
                            return <div key={program.id} className="database-single">
                                <div className="database-item">
                                    {!this.shouldDateBeVisible(program) ? <h3><b>Program date: {program.date}</b></h3> : null}
                                    {program.start_time && program.end_time ?
                                        <div>
                                            <p>Program starts: {moment(program.start_time.seconds * 1000).format('LT')}</p>
                                            <p>Program ends: {moment(program.end_time.seconds * 1000).format('LT')}</p>
                                        </div>
                                        : <div></div>}
                                    <p>Venue: {program.venue}</p>
                                </div>
                                <div className="database-delete pointer">
                                    <DeleteOutlined onClick={(e) => this.props.deleteProgram(e, program.id)} />
                                </div>
                            </div>
                        })
                    }
                </div>
                : <div className="empty-database">
                    <Empty description="There is no programs in database for this meeting" />
                </div>
        )
    }
}
