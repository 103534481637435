import React, { Component } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from '../../assets/escd_logo.svg'
import firebase from '../../services/firebase'
import './Login.css'
import { withRouter } from 'react-router-dom';



class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: ""
    }
  }

  componentDidMount() {
    const isUserActive = firebase.auth.currentUser;
    if(isUserActive) {
      this.props.history.push('/')
    }
  }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  onLogin = async (e) => {
    e.preventDefault();
    try {
      await firebase.login(this.state.email, this.state.password);
      this.props.history.push("/cms");
    } catch (error) {
      alert(error)
    }
  }
  render() {
    return (
      <div className="login-container">
        <img
          style={{ width: "200px", marginBottom: "10px" }}
          className="logo"
          src={logo}
          alt="logo"
          draggable="false"
        />
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input valid email"
              }
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              onChange={this.handleChange("email")}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              onChange={this.handleChange("password")}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              onClick={(e) => this.onLogin(e)}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default withRouter(Login);