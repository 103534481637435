import React, {useEffect, useState} from 'react';
import firebase from './services/firebase'
import AdminPanel from './components/AdminPanel'
import StudyClubMeetings from './components/StudyClubMeetings/StudyClubMeetings'
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom'
import AnnualMeetings from './components/AnnualMeetings/AnnualMeetings';
import SingleMeeting from './components/AnnualMeetings/SingleMeeting'
import SingleStudyClubMeeting from './components/StudyClubMeetings/SingleStudyClubMeeting'
import Login from './components/Login/Login';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {ProtectedRoute} from './components/ProtectedRoute'
import Header  from './components/Header/Header'

function App() {
  
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
		firebase.isInitialized().then(val => {
			setFirebaseInitialized(val)
		})
  }, [])

  const withContainer = () => (
    <div className="container">
          <Redirect exact from="/" to="/cms" />
          <ProtectedRoute path="/cms" component= {Header}/>
          <ProtectedRoute exact path="/cms" component= {AdminPanel} />
          <ProtectedRoute exact path="/cms/study-clubs" component= {StudyClubMeetings} />
          <ProtectedRoute exact path="/cms/annual-meetings" component= {AnnualMeetings} />
          <ProtectedRoute exact path={`/cms/annual-meetings/:id`} component= {SingleMeeting} />
          <ProtectedRoute exact path={`/cms/study-club-meetings/:id`} component= {SingleStudyClubMeeting} />
    </div>
    )

  return firebaseInitialized !== false ? (
      <Router>
        <Switch>
          <Route exact path="/cms/login" component= {Login} />
          <Route component={withContainer} />
          <Route render={() => <Redirect to={{pathname: "/"}} />} />          
        </Switch>
      </Router>
  ) : <div><Spin style={{display: 'flex', justifyContent: "center", alignItems:"center", height: '100vh'}} size="large" indicator={loadingIcon} /></div>
}

export default App;
