import React, { Component } from "react";
import "../styles//DataEntry.css";
import "../../../main.css";
import { Form, Input, Upload, Button } from "antd";
import {
  InboxOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,
} from "@ant-design/icons";
import SpeakersDatabase from './SpeakersDatabase'
import { Link } from 'react-router-dom';

export default class Speakers extends Component {
  state = {
    speakerName: "",
    speakerTitle: "",
    speakerImage: [],
    speakerBiography: "",
  };


  submitSpeaker = (e) => {
    this.props.submitSpeaker(e, this.state.speakerName, this.state.speakerTitle, this.state.speakerBiography)
    this.setState({
      speakerName: "",
      speakerTitle: "",
      speakerBiography: ""
    })
  }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    const { TextArea } = Input;
    const isDisabled = this.props.uploadFinished === false || this.state.speakerName === ""
    if (this.props.name === "Annual Meetings") {
      return (
        <div className="form-data">
          <div className="form-left">
            <div className="form-header">
              <h1><b>{this.props.name}</b></h1>
              <p style={{ fontSize: '18px' }}>Speaker</p>
            </div>
            <Form
              onSubmit={this.submitSpeaker}
              wrapperCol={{
                span: 24
              }}
              layout="horizontal"
              initialValues={{
                size: "large"
              }}
              size={"large"}
            >
              <Form.Item>
                <Input
                  placeholder="Name & surname"
                  onChange={this.handleChange("speakerName")}
                  value={this.state.speakerName}
                  defaultValue={this.state.speakerName}
                />
              </Form.Item>
              <Form.Item>
                <Input defaultValue={this.state.speakerTitle} value={this.state.speakerTitle} placeholder="Title" onChange={this.handleChange("speakerTitle")} />
              </Form.Item>
              <Form.Item>
                <TextArea defaultValue={this.state.speakerBiography} value={this.state.speakerBiography} style={{ resize: 'none' }} placeholder="Biography" onChange={this.handleChange("speakerBiography")} rows={4} />
              </Form.Item>
              <Form.Item>
                <Upload.Dragger onChange={this.props.handleImageChange} customRequest={this.props.imageUpload} onSubmit={this.props.onSubmit}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Upload Speaker Image
                  </p>
                </Upload.Dragger>
              </Form.Item>
              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" onClick={this.submitSpeaker} disabled={isDisabled} htmlType="submit">
                  Submit Data
                </Button>
              </Form.Item>
            </Form>

            <div className="form-buttons">
              <Link to='/cms/annual-meetings'>
                <Button
                  style={{ marginRight: "5px" }}
                  size="large"
                  icon={<StepBackwardOutlined />}
                  type="primary"
                  htmlType="button"
                  onClick={this.props.prevStep}
                >
                  Go Back
              </Button>
              </Link>
              <Button
                style={{ marginLeft: "5px" }}
                icon={<StepForwardOutlined />}
                size="large"
                type="primary"
                htmlType="button"
                onClick={this.props.nextStep}
              >
                Continue
              </Button>
            </div>
          </div>
          <div className="form-right">
            <div className="database-header">
              <h1><b>Database</b></h1>
              <p style={{ fontSize: '18px' }}>Current speakers in database</p>
            </div>
            <SpeakersDatabase speakers={this.props.speakers} isLoading={this.props.isLoading} deleteSpeaker={this.props.deleteSpeaker} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-data">
          <div className="form-left">
            <div className="form-header">
              <h1><b>{this.props.name}</b></h1>
              <p style={{ fontSize: '18px' }}>Speaker</p>
            </div>
            <Form
              onSubmit={this.submitSpeaker}
              wrapperCol={{
                span: 24
              }}
              layout="horizontal"
              initialValues={{
                size: "large"
              }}
              size={"large"}
            >
              <Form.Item>
                <Input
                  placeholder="Name & surname"
                  onChange={this.handleChange("speakerName")}
                  value={this.state.speakerName}
                  defaultValue={this.state.speakerName}
                />
              </Form.Item>
              <Form.Item>
                <Input defaultValue={this.state.speakerTitle} value={this.state.speakerTitle} placeholder="Title" onChange={this.handleChange("speakerTitle")} />
              </Form.Item>
              <Form.Item>
                <Button style={{ width: '100%' }} type="primary" onClick={this.submitSpeaker} disabled={isDisabled} htmlType="submit">
                  Submit Data
                </Button>
              </Form.Item>
            </Form>

            <div className="form-buttons">
              <Link to='/cms/study-clubs'>
                <Button
                  style={{ marginRight: "5px" }}
                  size="large"
                  icon={<StepBackwardOutlined />}
                  type="primary"
                  htmlType="button"
                  onClick={this.props.prevStep}
                >
                  Go Back
              </Button>
              </Link>
              <Button
                style={{ marginLeft: "5px" }}
                icon={<StepForwardOutlined />}
                size="large"
                type="primary"
                htmlType="button"
                onClick={this.props.nextStep}
              >
                Continue
              </Button>
            </div>
          </div>
          <div className="form-right">
            <div className="database-header">
              <h1><b>Database</b></h1>
              <p style={{ fontSize: '18px' }}>Current speakers in database</p>
            </div>
            <SpeakersDatabase speakers={this.props.speakers} isLoading={this.props.isLoading} deleteSpeaker={this.props.deleteSpeaker}/>
          </div>
        </div>
      );
    }

  }
}
