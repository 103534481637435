import React, { Component } from "react";
import "../../Adminpanel.css";
import "../styles/DataEntry.css";
import "../../../main.css";
import MeetingInfoDatabase from './MeetingInfoDatabase'
import { Form, Input, Upload, DatePicker, Button } from "antd";
import {
  InboxOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,

} from "@ant-design/icons";
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';

class MeetingInfo extends Component {
  state = {
    meeting_title: "",
    start_date: new Date(),
    end_date: new Date(),
    city: "",
    registration: ""
  };

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  };

  handleSubmit = (e) => {
    this.props.submitMeeting(e, this.state.meeting_title, this.state.start_date, this.state.end_date, this.state.city, this.state.registration)
    this.setState({
      meeting_title: "",
      city: "",

    })
  }

  disabledDate = (current) => {
    const startDate = moment(this.state.start_date)
    return current && current < startDate
  }


  render() {
    const isDisabled = this.props.uploadFinished === false || this.state.meeting_title === ''
    const isMovable = this.props.meetingId === ''

    return (
      <div className="form-data">
        <div className="form-left">
          <div className="form-header">
            <h1><b>{this.props.name}</b></h1>
            <p style={{ fontSize: '18px' }}>Meeting Info</p>
          </div>
          <Form
            wrapperCol={{
              span: 24
            }}
            layout="horizontal"
            initialValues={{
              size: "large"
            }}
            size={"large"}
          >
            <Form.Item>
              <Input
                value={this.state.meeting_title}
                placeholder="Meeting Title"
                onChange={this.handleChange("meeting_title")}
                defaultValue={this.state.meeting_title}
              />
            </Form.Item>
            <Form.Item>
              <DatePicker showToday={false} defaultValue={moment(this.state.start_date)} format={"DD/MM/YYYY"} placeholder="Start Date" onChange={(start_date) => this.setState({ start_date })} />
            </Form.Item>
            <Form.Item>
              <DatePicker showToday={false} format={"DD/MM/YYYY"} disabledDate={this.disabledDate} placeholder="End Date" onChange={(end_date) => this.setState({ end_date })} />
            </Form.Item>
            <Form.Item>
              <Input
                value={this.state.city}
                placeholder="City"
                onChange={this.handleChange("city")}
                defaultValue={this.state.city}
              />
            </Form.Item>
            <Form.Item>
              <Input
                value={this.state.registration}
                placeholder="Registration"
                onChange={this.handleChange("registration")}
                defaultValue={this.state.registration}
              />
            </Form.Item>
            <Form.Item>
              <Upload.Dragger customRequest={this.props.imageUpload} onChange={this.props.handleImageChange}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Upload Meeting Image
                  </p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item>
              <Button style={{ width: '100%' }} type="primary" disabled={isDisabled} htmlType="submit" onClick={this.handleSubmit}>
                Submit Data
                </Button>
            </Form.Item>
          </Form>

          <div className="form-buttons">
            <Link to="/cms">
              <Button
                style={{ marginRight: "5px" }}
                size="large"
                icon={<StepBackwardOutlined />}
                type="primary"
                htmlType="button"
              >              Go Back
              </Button>
            </Link>

            <Link to={`/${this.props.name.toLowerCase().trim().split(/\s+/).join('-')
              }/${this.props.meetingId}`}>
              <Button
                disabled={isMovable}
                style={{ marginLeft: "5px" }}
                icon={<StepForwardOutlined />}
                size="large"
                type="primary"
                htmlType="button"
                onClick={this.props.nextStep}
              >
                Continue
              </Button>
            </Link>
          </div>
        </div>
        <div className="form-right">
          <div className="database-header">
            <h1><b>Database</b></h1>
            <p style={{ fontSize: '18px' }}>Current meetings in database</p>
          </div>
          <MeetingInfoDatabase name={this.props.name} currentScreen={"Meeting Info"} currentMeetings={this.props.currentMeetings} deleteMeeting={this.props.deleteMeeting} isLoading={this.props.isLoading} />
        </div>

      </div>

    );
  }
}

export default withRouter(MeetingInfo)